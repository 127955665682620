import React, { forwardRef } from 'react';
import './index.css';

export default forwardRef((props, ref) => {
  return (
    <div className="part-container" ref={ref}>
      <img className="background-decorators super-bean" src="./images/super-bean.png" alt="background decorators" />
      <img className="background-decorators little-thin-bean" src="./images/little-thin-bean.png" alt="background decorators" />
      <img className="background-decorators little-fat-bean" src="./images/little-fat-bean.png" alt="background decorators" />
      <div className="section about-us">
        <div className="general-info">
          <p className="title">
            ABOUT FANSUP
          </p>
          <div className="content">
            FansUp was founded to be the maker of best-in-class products. It is not only a fast-growing technology company, but also the choice of millions of users around the globe.
          </div>
        </div>
        <div className="content-items">
          <div className="item">
            <img src="./images/design-icon.png" alt="design icon" className="team-aspect-icon" />
            <p className="title">Elegant User Interfaces</p>
            <p className="content center">We believe in simplicity and devote attention to detail. We create vibrant, elegant designs while gauging customers’ feedback every step of the way.</p>
          </div>
          <div className="item">
            <img src="./images/tech-icon.png" alt="design icon" className="team-aspect-icon" />
            <p className="title">World-Class Technology</p>
            <p className="content center">World-class technology means more stability at higher speeds, bringing better user experience.</p>
          </div>
          <div className="item">
            <img src="./images/experience-icon.png" alt="design icon" className="team-aspect-icon" />
            <p className="title">Rich Experience</p>
            <p className="content center">Our rich experience with launching and marketing app overseas enables us to both increase market share and better avoid risk.</p>
          </div>
        </div>
      </div>
      <div className="section full-experience">
        <div className="block left">
          <div className="images">
            <img className="experienced-people" src="./images/people-experience-img.png" alt="experienced people" />
          </div>
        </div>
        <div className="block right">
          <div className="title">Exceptional Apps</div>
          <p className="content">We make exceptional mobile applications with a primary focus on intuitive designs and excellent user experiences. The effort to create such apps has made us a competitive player in the market.</p>
        </div>
      </div>
      <div className="section data-analyzer">
        <div className="block left">
          <div className="title">Big Data Analytics</div>
          <p className="content">The big data analytics of millions of global users tells us what they need and what the market trend is.</p>
          <p className="content">And we have over 10 millions customers around the globe.</p>
        </div>
        <div className="block right">
          <div className="images">
            <img className="data-analyzer" src="./images/data-analyzer.png" alt="data analyzer board" />
          </div>
        </div>
      </div>
    </div>
  )
})

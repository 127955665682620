import React from 'react';
import './index.css';
import JobDetailTitle from '../JobDetailTitle';
import CommonSegment from '../CommonSegment';
import ListSegment from '../ListSegment';

export default (props) => {
  let { jobDetail } = props;

  const createIntroductions = (items) => {
    return items.map((it, index) => {
      return <CommonSegment key={index} textContent={it} />
    })
  }

  return (
    <div className={`job-detail-container ${props.className}`}>
      <JobDetailTitle titleName={jobDetail.title} />
      {createIntroductions(jobDetail.prefixIntroductions)}
      <ListSegment title={'Responsibilities'} items={jobDetail.responsibilities} />
      <ListSegment title={'Qualifications'} items={jobDetail.qualifications} />
      <ListSegment title={'What We Offer'} items={jobDetail.whatWeOffers} />
      {createIntroductions(jobDetail.postfixIntroductions)}
    </div>
  )
};

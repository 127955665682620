import React, { forwardRef } from 'react';
import './index.css';

export default forwardRef((props, ref) => {
  return (
    <div className='footer-section' ref={ref}>
      <div className='footer-container'>
        <div className='flex-item logo'><img className='logo-img' src='./images/logo-colored.png' alt='logo' /></div>
        <div className='flex-item end-part'>
          <div className='contact'>Contact: support@fansup.mobi</div>
          <div className='copyright'>© Copyright {(new Date()).getFullYear()} fansup.mobi</div>
        </div>
      </div>
    </div>
  )
})

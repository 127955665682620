import React from 'react';
import './index.css';

export default (props) => {
  const createListItems = (items) => {
    return items.map((it, index) => {
      return (
        <li key={index} className='item'>{it}</li>
      )
    })
  }

  return (
    <div className='list-segment-container'>
      <div className='title'>{props.title}</div>
      <ul>
        {createListItems(props.items)}
      </ul>
    </div>
  )
};

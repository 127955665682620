import React, { forwardRef, useState, useEffect } from 'react';
import './index.css';
import { jobs_simple } from '../../services/JobPositionsProvider';

export default forwardRef((props, ref) => {
  let [currentJobId, setCurrentJobId] = useState('1');

  const buildPositionItems = () => {
    let simpler = jobs_simple.reduce((acc, cur, index) => {
      acc[Number(index % 2)].push(cur);
      return acc;
    }, [[], []]);

    return simpler.map((item, index) => {
      return (
        <div className='lists' key={index}>
          <ul>
            {
              item.map((p, index) => {
                return (
                  <li key={index} className={`position-title ${currentJobId === p.id ? 'active' : ''}`}>{ p.title }</li>
                )
              })
            }
          </ul>
        </div>
      )
    })
  }

  const getRandomNumber = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  const getRandomJob = () => {
    return jobs_simple[getRandomNumber(0, jobs_simple.length)]
  }

  useEffect(() => {
    var intervalID = window.setInterval(() => {
      let job_id = currentJobId;
      while (job_id === currentJobId) {
        job_id = getRandomJob().id
      }
      setCurrentJobId(job_id);
    }, 3000);
    return () => {
      window.clearInterval(intervalID);
    }
  }, [props])

  return (
    <div className='section' ref={ref}>
      <div className='block left'>
        <img src={`./images/${jobs_simple.filter((item) => {return item.id === currentJobId})[0].image_name}.png`} alt='position avatar' />
      </div>
      <div className='block right join-us'>
        <div className='title'>Join Us</div>
        <div className='content'>
          <p>We team up with all the best talents, and provide them with our best resources. Not only does FansUp help people succeed, but it is also the place where dreams begin.</p>
        </div>
        <div className='positions'>
          { buildPositionItems() }
        </div>
        <a className='home-button' href='/job_details'>Learn More</a>
      </div>
    </div>
  )
})

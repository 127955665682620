import React, { useState } from 'react';
import './index.css';
import Navigator from '../../components/Navigator';
import Footer from '../../components/Footer';
import JobDetailContent from '../../components/JobDetailContent';
import { jobs_simple, getJobFullInfo } from '../../services/JobPositionsProvider';

export default (props) => {
  let [currentJobId, setCurrentJobId] = useState('1');

  const selectJob = (job_id) => {
    return () => {
      setCurrentJobId(job_id)
    }
  }

  const buildJobTitles = () => {
    return jobs_simple.map((job, index) => {
      return (
        <div key={index} className={`top-job-title ${job.id === currentJobId ? 'active' : ''}`} onClick={selectJob(job.id)}>{job.title}</div>
      )
    })
  }

  return (
    <div>
      <Navigator theme={'normal'} />
      <div className='job-detail-page-container'>
        <div className='job-titles'>
          { buildJobTitles() }
        </div>
        <JobDetailContent jobDetail={getJobFullInfo(currentJobId)} />
      </div>
      <Footer />
    </div>
  )
}

import React from 'react';
import './index.css';

export default () => {
  return (
    <div className="section product-ig part-container">
      <img className="background-decorators medium-right-bean" src="./images/medium-right-bean.png" alt="background decorators" />
      <div className="block left is-text">
        <div className="title">IG ANALYZER</div>
        <div className="content red bolder">
          Ig Analyzer tracks & mass unfollows your unfollowers, and measures your account performance.
        </div>
        <p className="content">It tracks your followers, mass unfollows your unfollowers, shows your best and ghost followers and gives insights into your posts and stories.
    </p>
        <a className="home-button" href="https://appsto.re/us/BJZdgb.i">Learn More</a>
      </div>
      <div className="block right">
        <div className="content-img">
          <img src="./images/ig-content-img.png" alt="screen shot of ig analyzer" />
        </div>
      </div>
    </div>
  )
}

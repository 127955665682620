import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import 'bulma/css/bulma.css';
import Home from './pages/Home';
import JobDetails from './pages/JobDetails';

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/job_details" component={JobDetails} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;

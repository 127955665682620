import React, { useState, useEffect } from 'react';
import Navigator from '../../components/Navigator';
import HomeBanner from '../../components/HomeBanner';
import HomeSectionRff from '../../components/HomeSectionRff';
import HomeSectionIg from '../../components/HomeSectionIg';
import HomeSectionAboutUs from '../../components/HomeSectionAboutUs';
import HomeSectionJoinUs from '../../components/HomeSectionJoinUs';
import Footer from '../../components/Footer';
import ScrollableAnchor from 'react-scrollable-anchor';

export default () => {
  let [navTheme, setNavTheme] = useState('hot');
  let [scrollEventRegisterFlag] = useState(0);

  let scrollHandler = () => {
    if(window.scrollY > 600){
      setNavTheme('normal')
    } else {
      setNavTheme('hot')
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);
    return () => {
      document.removeEventListener('scroll', scrollHandler);
    }
  }, [scrollEventRegisterFlag])

  return (
    <div>
      <Navigator theme={navTheme} />
      <HomeBanner />
      <HomeSectionRff />
      <HomeSectionIg />
      <ScrollableAnchor id={'about'}>
        <HomeSectionAboutUs />
      </ScrollableAnchor>
      <ScrollableAnchor id={'join_us'}>
        <HomeSectionJoinUs />
      </ScrollableAnchor>
      <ScrollableAnchor id={'contact'}>
        <Footer />
      </ScrollableAnchor>
    </div>
  )
}

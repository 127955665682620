import React, { useState } from 'react';
import './index.css';
import { Navbar, NavbarBrand, NavbarItem, NavbarBurger, NavbarMenu, NavbarStart, NavbarEnd } from 'bloomer';

export default (props) => {
  let [isActive, setIsActive] = useState(false);

  let onClickNav = () => {
    setIsActive(!isActive);
  }

  return (
    <div className={`nav-section ${props.theme}`}>
        <div style={{position: 'relative'}}>
          <Navbar className={`nav-component ${props.theme}`}>
            <NavbarBrand>
                <NavbarItem>
                  { props.theme === 'hot' ?  <img className='logo-img' src='./images/logo.png' alt='logo' /> : <img className='logo-img' src='./images/logo-colored.png' alt='logo' />}
                </NavbarItem>
                <NavbarBurger className={`customized-burger ${props.theme}`} isActive={isActive} onClick={onClickNav} />
            </NavbarBrand>
            <NavbarMenu className={`${props.theme}`} isActive={isActive} onClick={ onClickNav }>
                <NavbarStart></NavbarStart>
                <NavbarEnd>
                  <a className={`nav-link ${props.currentNav === 'PRODUCTS' ? 'active' : ''} ${props.theme}`} href='/'>Home</a>
                  <a className={`nav-link ${props.currentNav === 'ABOUT' ? 'active' : ''} ${props.theme}`} href='/#about'>About</a>
                  <a className={`nav-link ${props.currentNav === 'JOIN_US' ? 'active' : ''} ${props.theme}`} href='/#join_us'>Join Us</a>
                  <a className={`nav-link ${props.currentNav === 'CONTACT' ? 'active' : ''} ${props.theme}`} href='/#contact'>Contact</a>
                </NavbarEnd>
            </NavbarMenu>
          </Navbar>
        </div>
      </div>
  )
}

import React from 'react';
import './index.css';

export default () => {
  return (
    <div className="section product-rff part-container">
      <img className="background-decorators medium-left-bean" src="./images/medium-left-bean.png" alt="background decorators" />
      <img className="background-decorators round-bean" src="./images/round-bean.png" alt="background decorators" />
      <img className="background-decorators little-heavy-bean" src="./images/little-heavy-bean.png" alt="background decorators" />
      <div className="block left">
        <div className="content-img">
          <img src="./images/rff-content-img.png" alt="screen shot of run for fit" />
        </div>
      </div>
      <div className="block right is-text">
        <div className="title">RUN FOR FIT</div>
        <div className="content red bolder">
          Run For Fit provides you with customized daily meal plans based on your fitness level.
        </div>
        <p className="content">We provide plentiful healthy and detailed recipes to suit your taste. The combination of running and healthy diet can help boost your performance and achieve the optimal fitness result.
    </p>
        <a className="home-button" href="https://itunes.apple.com/us/app/runforfit/id1381315131?mt=8">Learn More</a>
      </div>
    </div>
  )
}

const jobs_simple = [
  {
    id: '1',
    title: 'Senior UI Designer',
    image_name: 'ui-designer',
    description: `We’re looking for a Senior UI Designer who will thrive in our energetic and entrepreneurial work environment. Someone who loves the craft of design and is forward-thinking, living and breathing the newest trends in screen-based design—who ...`
  },
  {
    id: '2',
    title: 'Senior iOS Developer',
    image_name: 'ios-developer',
    description: `We are looking for a highly motivated, self-starter that thrives working independently as well as with a team. Enthusiasm and drive for crafting a great user experience is a must. If you enjoy taking a complicated idea/process and ...`
  },
  {
    id: '3',
    title: 'Senior Android Developer ',
    image_name: 'android-developer',
    description: `We’re looking for a Senior Android Developer to work on our Engineering team. The perfect candidate is a team player who's hungry to learn and grow their skill sets as well as pick up new ones on the fly.`
  },
  {
    id: '4',
    title: 'Motion Graphics Designer',
    image_name: 'graphics-designer',
    description: `We’re looking for a Motion Graphics Designer / Video Producer to work on our Creative team. The perfect candidate is a team player who's hungry to learn and grow their skill sets as well as pick up new ones on the fly.`
  },
  {
    id: '5',
    title: 'Product Manager',
    image_name: 'product-manager',
    description: `We are looking for an experienced product manager that is motivated to solve problems and leverage new technologies to power business success. You will be responsible for driving a variety of FansUp initiatives to new ...`
  },
  {
    id: '6',
    title: 'SEO Marketing Manager',
    image_name: 'marketing-manager',
    description: `Do you live and breathe SEO? Are you ready to take your career to the next level? Join a team of passionate SEO marketers, that discuss, test and verify the real world SEO hypothesis on daily basis. Take your knowledge of Google and ...`
  }
]

const jobs_full = [
  {
    id: '1',
    title: 'Senior UI Designer',
    description: `We’re looking for a Senior UI Designer who will thrive in our energetic and entrepreneurial work environment. Someone who loves the craft of design and is forward-thinking, living and breathing the newest trends in screen-based design—who loves creating innovative experiences that constantly raise the bar.`,
    prefixIntroductions: [
      'We’re looking for a Senior UI Designer who will thrive in our energetic and entrepreneurial work environment. Someone who loves the craft of design and is forward-thinking, living and breathing the newest trends in screen-based design—who loves creating innovative experiences that constantly raise the bar.'
    ],
    postfixIntroductions: [],
    responsibilities: [
      'Take ownership of the project from blank page to print with a heavy amount of interface and interaction design.',
      'Design/define visuals interfaces that enable our customers to seamlessly and easily shop across channels and help our brands deliver the best customer service from product discovery through delivery.',
      'Develop and produce design patterns',
      'Using good judgment, research and data to back design decisions',
      'Work on repeatable designs that grow our design system',
      'Brainstorm and collaborate with product, business system analysts, developers, and other stakeholders to identify user interface needs and define use cases.'
    ],
    qualifications: [
      'BS/BA degree required',
      '3 years (+) experience in any of the following areas: User Experience, Information Architecture, or Creative DesignInternet',
      'Strong product design portfolio that shows design to solve customer needs',
      'Must be able to clearly communicate, sell, persuade at all levels of management, up, down and across organization'
    ],
    whatWeOffers: [
      'Group insurance (health, life etc.) ',
      'Sick leave',
      '7 days+ paid vacation after 6 months',
      'Equity incentives',
      'Unlimited vacation package',
      'Daily catered lunch and fully stocked kitchen',
      'Employee referrals rewards',
      'Diverse & inclusive culture'
    ]
  },
  {
    id: '2',
    title: 'Senior iOS Developer',
    description: `We are looking for a highly motivated, self-starter that thrives working independently as well as with a team. Enthusiasm and drive for crafting a great user experience is a must. If you enjoy taking a complicated idea/process and simplifying it for the user into a beautiful and compelling experience then our team may be the right place for you.`,
    prefixIntroductions: [
      'We are looking for a highly motivated, self-starter that thrives working independently as well as with a team. Enthusiasm and drive for crafting a great user experience is a must. If you enjoy taking a complicated idea/process and simplifying it for the user into a beautiful and compelling experience then our team may be the right place for you.'
    ],
    postfixIntroductions: [],
    responsibilities: [
      'Deep customer empathy and ability to quickly translate insights, whiteboard sessions, napkin sketches and water cooler talks into mockups, wireframes, and working proof-of-concept code',
      'Work with design and business stakeholders to successfully implement features from start to finish.',
      'Collaborate with our design team to bring creative ideas to the UI and craft a beautiful iOS experience',
      'Participate in code reviews',
      'Participate in client architecture decisions',
      'Gathering and applying performance and instrumentation knowledge to the client app'
    ],
    qualifications: [
      'Bachelor’s Degree in Computer Science or equivalent experience',
      'Mastery of iOS with a minimum of 5 years professional experience',
      'You’ve shipped one or more products to the App Store (include links for review)',
      'Familiarity with good design patterns and best engineering practices within iOS',
      'Able to evaluate technical approaches to problems and provide viable alternative solutions',
      'Able to clearly and effectively communicate with others of varying technical levels',
      'Experience with Swift',
      'Experience building custom UI'
    ],
    whatWeOffers: [
      'Group insurance (health, life etc.) ',
      'Sick leave',
      '7 days+ paid vacation every year',
      'Equity incentives',
      'Unlimited vacation package',
      'Daily catered lunch and fully stocked kitchen',
      'Employee referrals rewards',
      'Diverse & inclusive culture'
    ]
  },
  {
    id: '3',
    title: 'Senior Android Developer ',
    description: `We’re looking for a Senior Android Developer to work on our Engineering team. The perfect candidate is a team player who's hungry to learn and grow their skill sets as well as pick up new ones on the fly.`,
    prefixIntroductions: [
      `We’re looking for a Senior Android Developer to work on our Engineering team. The perfect candidate is a team player who's hungry to learn and grow their skill sets as well as pick up new ones on the fly.`
    ],
    postfixIntroductions: [],
    responsibilities: [
      'Work with Product and QA to design and develop Android applications',
      'Work with peers to find efficient solutions for architectural problems',
      'Lead code reviews and tech sessions',
      'Improve test automation by writing unit tests',
      'Implement CI/CD processes',
      'Design and implement REST Services',
      'Keep up to date with open-source Android ecosystem'
    ],
    qualifications: [
      'Mastery of Android with a minimum of 5 years professional Android experience',
      'Expert understanding of Java and Android SDK',
      'Strong knowledge of Android UI design principles, patterns and best practices',
      'Hands on experience integrating android applications to backend services',
      'Strong problem-solving skills and a detail-oriented mindset',
      'Ability to multi-task, prioritize and meet deadlines in a fast-paced environment',
      'Experience working with product managers, designers and QA'
    ],
    whatWeOffers: [
      'Group insurance (health, life etc.) ',
      'Sick leave',
      '7 days+ paid vacation every year',
      'Equity incentives',
      'Unlimited vacation package',
      'Daily catered lunch and fully stocked kitchen',
      'Employee referrals rewards',
      'Diverse & inclusive culture'
    ]
  },
  {
    id: '4',
    title: 'Motion Graphics Designer',
    description: `We’re looking for a Motion Graphics Designer / Video Producer to work on our Creative team. The perfect candidate is a team player who's hungry to learn and grow their skill sets as well as pick up new ones on the fly.`,
    prefixIntroductions: [
      `We’re looking for a Motion Graphics Designer / Video Producer to work on our Creative team. The perfect candidate is a team player who's hungry to learn and grow their skill sets as well as pick up new ones on the fly.`
    ],
    postfixIntroductions: [],
    responsibilities: [
      'Understanding of typography, movement and layout',
      'Proficient in After Effects (or Premiere), Photoshop, and Illustrator',
      'Self-motivated with the ability to work independently',
      'Expert eye for detail and pixel perfection; no detail is too small or insignificant',
      'Ability to manage and prioritize multiple projects while meeting tight deadlines',
      'Excellent interpersonal, communication skills, and good attitude in a collaborative, fast paced environment',
      'Can clearly articulate design decisions',
      'Can transform high-level business needs into engaging creative'
    ],
    qualifications: [
      'A bachelor’s degree in Graphics Design or a design field',
      'Must have a strong portfolio',
      '3+ years of experience as a Graphic designer/Animator',
      'Able to work a flexible schedule, including nights and the rare but occasional weekend',
      'Adobe Photoshop and Illustrator (Required)',
      'After Effects (Required)',
      'Adobe Premiere Pro (Required)',
      'Cinema 4D or 3D (Proficient)',
      'Proficient in MAC'
    ],
    whatWeOffers: [
      'Group insurance (health, life etc.)',
      'Sick leave',
      '7 days+ paid vacation every year',
      'Equity incentives',
      'Unlimited vacation package',
      'Daily catered lunch and fully stocked kitchen',
      'Employee referrals rewards',
      'Diverse & inclusive culture'
    ]
  },
  {
    id: '5',
    title: 'Product Manager',
    description: `We are looking for an experienced product manager that is motivated to solve problems and leverage new technologies to power business success. You will be responsible for driving a variety of FansUp initiatives to new heights in a fast-paced, highly collaborative environment, which encourages you to wear many hats.`,
    prefixIntroductions: [
      'We are looking for an experienced product manager that is motivated to solve problems and leverage new technologies to power business success. You will be responsible for driving a variety of FansUp initiatives to new heights in a fast-paced, highly collaborative environment, which encourages you to wear many hats.'
    ],
    postfixIntroductions: [],
    responsibilities: [
      'Become the subject matter expert on user behavior',
      'Partner with design team to develop wireframes and mocks of proposed experiences',
      'Be the nexus of communication and planning between customers, support, designers and engineers. Lead each of these audiences with confidence and authority',
      'Manage the end-to-end development cycle by communicating requirements and partnering with engineering throughout',
      'Make prioritization decisions based on understanding of ROI and impact on KPIs',
      'Collect and analyze data related to user behavior',
      'Work with users to test and research ideas',
      'Develop product marketing materials',
      'Troubleshoot issues as they arise'
    ],
    qualifications: [
      '3-5 years designing and delivering world-class experiences in a Product Management role',
      'Demonstrated experience managing the end-to-end product lifecycle for consumer or enterprise offerings',
      'Ability to develop and execute a successful product roadmap',
      'Ability to collect and analyze data',
      'Experience with Agile development and methodologies',
      'Proven expertise leading cross-functional teams that together launch on-time and on-budget web or mobile software product offerings',
      'Strong project management skills, including the ability to think and lead complex projects simultaneously',
      'Successful history of delivering customer-driven products designed to create a great customer experience',
      'BS or BA Degree preferred or equivalent work experience'
    ],
    whatWeOffers: [
      'Group insurance (health, life etc.)',
      'Sick leave',
      '7 days+ paid vacation every year',
      'Equity incentives',
      'Unlimited vacation package',
      'Daily catered lunch and fully stocked kitchen',
      'Employee referrals rewards',
      'Diverse & inclusive culture'
    ]
  },
  {
    id: '6',
    title: 'SEO Marketing Manager',
    description: `Do you live and breathe SEO? Are you ready to take your career to the next level? Join a team of passionate SEO marketers, that discuss, test and verify the real world SEO hypothesis on daily basis. Take your knowledge of Google and search optimization to a whole new level by applying with us. Break from the old, boring SEO routines and be on the cutting edge of SEO knowledge.`,
    prefixIntroductions: [
      `Do you live and breathe SEO? Are you ready to take your career to the next level? Join a team of passionate SEO marketers, that discuss, test and verify the real world SEO hypothesis on daily basis. Take your knowledge of Google and search optimization to a whole new level by applying with us. Break from the old, boring SEO routines and be on the cutting edge of SEO knowledge.`
    ],
    postfixIntroductions: [],
    responsibilities: [
      'Independently lead user-acquisition and demand-capture campaigns across the conversion funnel in search-space for English speaking regions (US / UK / CA)',
      'Own the portfolio of Google UAC & Apple-Search-Ads campaigns to ensure revenue goals are delivered each quarter',
      'Work closely with colleagues in the broader SEM team to ensure that we’re maximizing ROI for the company across both the channels',
      'Work closely with analytics team to identify growth opportunities, assess performance and build an internal understanding of incrementality',
      'Evangelize the benefits of app across the organization and influence other teams to prioritize it'
    ],
    qualifications: [
      'At least 4-5 years’ experience specializing in the AdWords & App space',
      'Data-oriented person',
      'Love the search space, have rich experience in SEM / AdWords and believe it to be a great demand-capture platform',
      'Have proven experience in successfully delivering impact through Acquisition & Engagement campaigns in search space (primarily Google)',
      'Have first-hand experience (or good knowledge of) how ad-tech products work & familiarity with Tableau / SQL is a huge plus'
    ],
    whatWeOffers: [
      'Group insurance (health, life etc.)',
      'Sick leave',
      '7 days+ paid vacation every year',
      'Equity incentives',
      'Unlimited vacation package',
      'Daily catered lunch and fully stocked kitchen',
      'Employee referrals rewards',
      'Diverse & inclusive culture'
    ]
  }
]

const getJobFullInfo = (id) => {
  return jobs_full.filter((jf) => {
    return jf.id === id;
  })[0];
}

const getOrderedJobs = (leadJobId , jobs) => {
  while (jobs[0].id !== leadJobId) {
    let shifted = jobs.shift();
    jobs.push(shifted);
  }
  return jobs;
}

const getOrderedJobsWithFullInfo = (leadJobId) => {
  var jobs = jobs_full;
  return getOrderedJobs(leadJobId, jobs);
}

export default {
  jobs_simple,
  jobs_full,
  getJobFullInfo,
  getOrderedJobsWithFullInfo
}
export { jobs_simple, jobs_full, getJobFullInfo, getOrderedJobsWithFullInfo };

import React from 'react';
import './index.css';
import { Swiper, Slide } from 'react-dynamic-swiper'
import 'react-dynamic-swiper/lib/styles.css'

export default () => {

  return (
    <div className='topBanner'>
      <Swiper
        swiperOptions={{
          slidesPerView: 'auto',
          loopedSlides: 2,
          speed: 1000,
          autoplay: {
            delay: 3000,
          }
        }}
        loop={true}
        navigation={false}
      >
        <Slide>
          <div className="swiper-slide banner-ig">
            <div className="banner-content-container">
              <div className="left-block">
                <div className="app-name">IG ANALYZER</div>
                <div className="app-description">
                  <div>INSTANT ANALYSIS</div>
                  <div>OF IG FOLLOWERS</div>
                </div>
                <p className="more-description">Ig Analyzer tracks & mass unfollows your unfollowers, and measures your account performance.</p>
                <a className="hot" href="https://appsto.re/us/BJZdgb.i">Learn More</a>
              </div>
              <div className="right-block">
                <div className="horizontal-image-group">
                  <img className="small" src="./images/ig-screen1.png" alt="screen shot of ig analyzer" />
                  <img className="bigger" src="./images/ig-screen2.png" alt="screen shot of ig analyzer" />
                </div>
              </div>
            </div>
          </div>
        </Slide>
        <Slide>
          <div className="swiper-slide banner-rff">
            <div className="banner-content-container">
              <div className="left-block">
                <div className="app-name">RUN FOR FIT</div>
                <div className="app-description">
                  <div>PERSONAL</div>
                  <div>FITNESS TRAINER</div>
                </div>
                <p className="more-description">Run For Fit provides you with customized daily meal plans based on your fitness level.</p>
                <a className="hot" href="https://itunes.apple.com/us/app/runforfit/id1381315131?mt=8">Learn More</a>
              </div>
              <div className="right-block">
                <div className="horizontal-image-group">
                  <img className="small" src="./images/rff-screen1.png" alt="screen shot of run for fit" />
                  <img className="bigger" src="./images/rff-screen2.png" alt="screen shot of run for fit" />
                </div>
              </div>
            </div>
          </div>
        </Slide>
      </Swiper>
    </div>
  )
}
